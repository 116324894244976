<template>
	<e-select
		v-model="internalValue"
		track-by="code"
		label="title"
		:options="locales"
	/>
</template>

<script>
export default {
	name: 'LocaleInput',
	props: {
		value: {
			type: String
		}
	},
	data: () => ({
		locales: []
	}),
	created() {
		this.locales = this.getLocales()
	},
	computed: {
		internalValue: {
			get() {
				return this.locales.find((locale) => {
					return locale.code === this.value
				})
			},
			set(val) {
				this.$emit('input', val.code)
			}
		}
	}
}
</script>
